import PageSectionsComponent from '@wix/thunderbolt-elements/src/thunderbolt-core-components/PageSections/viewer/PageSections';


const PageSections = {
  component: PageSectionsComponent
};


export const components = {
  ['PageSections']: PageSections
};

